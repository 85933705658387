<template>
    <div>
        <el-container>
            <el-header>
                <el-row>
                    <el-col :span="2">备注</el-col>
                    <el-col :span="4">
                        <el-input v-model="params.remark" size="mini"></el-input>
                    </el-col>
                    <el-col :span="2">
                        <el-button @click="loaddata" size="mini">查询</el-button>
                    </el-col>
                </el-row>
            </el-header>
            <el-container>
                <el-table :data="rs"height="600">
                    <el-table-column prop="accountName" label="账套" width="180px"></el-table-column>
                    <el-table-column prop="detail" label="设置编码" width="180px"></el-table-column>
                    <el-table-column prop="isstop" label="是否停用" width="180px"></el-table-column>
                    <el-table-column prop="remark" label="说明" width="180px"></el-table-column>
                    <el-table-column prop="sycval" label="源值"  width="180px"></el-table-column>
                    <el-table-column prop="val" label="同步值" width="180px"></el-table-column>
                </el-table>
           </el-container>
        </el-container>


        
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    export default {
        name: "account",
        extends: ax.control(),
        data() {
            return {};
        },
        mounted() {
            this.loaddata();
        },
        methods: {
            test() {
                ax.base
                    .post("/test/queryUserInfo", this.login)
                    .then(function(rs) {
                        console.log(rs);
                    })
                    .catch(function(err) {
                        alert(JSON.stringify(err.response.data));
                    });

            },
            loaddata() {
                let _this = this;
                ax.base
                    .post("/syc/getDetail", _this.params)
                    .then((data) => {
                        _this.rs = data.data;
                        console.log(data);
                    })
                    .catch((data) => {
                        console.log(data);
                    });
            },
        },
    };
</script>